import {PhoenixClickable} from './phoenix-clickable';

export class PhoenixElement implements PhoenixClickable {
  private _classes: Object;
  private _description: string;
  private _id: string;
  private _label: string;
  private _priority: number;
  private _properties: Object;
  private _type: string; // TODO: Enum

  public constructor(
    description: string,
    id: string,
    label: string,
    priority: number,
    properties: Object,
    type: string,
    classes: Object,
    click?: () => void,
  ) {
    this._description = description;
    this._id = id;
    this._label = label;
    this._priority = priority;
    this._properties = properties;
    this._type = type;
    this._classes = classes;
    this.click = click;
  }

  public get classes(): Object {
    return this._classes;
  }

  public set classes(value: Object) {
    this._classes = value;
  }

  public get description(): string {
    return this._description;
  }

  public set description(value: string) {
    this._description = value;
  }

  public get id(): string {
    return this._id;
  }

  public set id(value: string) {
    this._id = value;
  }

  public get label(): string {
    return this._label;
  }

  public set label(value: string) {
    this._label = value;
  }

  public get priority(): number {
    return this._priority;
  }

  public set priority(value: number) {
    this._priority = value;
  }

  public get properties(): Object {
    return this._properties;
  }

  public set properties(value: Object) {
    this._properties = value;
  }

  public get type(): string {
    return this._type;
  }

  public set type(value: string) {
    this._type = value;
  }

  public click(): void {
  }
}
