import { PhoenixElement } from './phoenix-element';

export class PhoenixIconElement extends PhoenixElement {
  public icon: string;
  public spinner: boolean;
  public name: "icon";

  public constructor(
    description: string,
    id: string,
    label: string,
    priority: number,
    properties: Object,
    type: string,
    classes: Object,
    icon: string,
    click?: () => void,
    clickWithId?: (id: String) => Promise<void>,
    spinner?: boolean,
  ) {
    super(description, id, label, priority, properties, type, classes, click);
    this.icon = icon;
    this.spinner = spinner;
    this.name = "icon";
  }
}
