export class Group {
  public id: string;
  public name: string;
  public userIds: string[];
  public role: string;
  public type: string;
  public notification: boolean;
  public pushNotification: boolean;

  public constructor(group?: Partial<Group>) {
    Object.assign(this, group);
  }
}
