import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { PhoenixDialogButton } from '../../../components/phoenix-dialog-new/classes/phoenix-dialog-button';
import { AssetDto } from '../../../gapicon/asset/dto/asset-dto';

import { PhoenixTenantSelectorDialogData } from './classes/PhoenixTenantSelectorDialogData';
import { MatTooltip } from '@angular/material/tooltip';
import { PhoenixIconElement } from '@phoenix/classes/phoenix-icon-element';
import { PhoenixIcon } from '@phoenix/enums/phoenix-icon.enum';

@Component({
  selector: 'phoenix-tenant-selector-dialog',
  templateUrl: './phoenix-tenant-selector-dialog.component.html',
  styleUrls: ['./phoenix-tenant-selector-dialog.component.scss'],
})
export class PhoenixTenantSelectorDialogComponent implements OnInit {

  public buttons: PhoenixDialogButton[];

  public icon: PhoenixIconElement;

  public isTenantDescriptionVisible: boolean = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: PhoenixTenantSelectorDialogData,
    private dialogRef: MatDialogRef<PhoenixTenantSelectorDialogComponent>,
  ) {
  }

  public ngOnInit(): void {

    this.icon = new PhoenixIconElement(undefined, undefined, undefined, undefined, undefined, undefined, { 'mat-card-avatar': true, 'circle': true }, PhoenixIcon.INFO, () => { this.isTenantDescriptionVisible = !this.isTenantDescriptionVisible; }
    );

    if (this.data.cancelButton) {
      this.buttons = [
        new PhoenixDialogButton({
          label: 'WIZARDS.TENANT.CANCEL',
          click: (): void => this.dialogRef.close(undefined),
        }),
      ];
    }
  }

  public selectTenant(tenant: AssetDto): void {
    this.dialogRef.close([tenant]);
  }

}
