<phoenix-dialog-new [buttons]="buttons" [subtitle]="data.subtitle" [title]="data.title" [icon]="icon">
  <div class="mt-24"></div>
  <div *ngFor="let tenant of data.tenants" class="mt-16 mb-16" fxLayout="column" fxLayoutAlign="space-between center">
    <div>
      <button class="button" (click)="selectTenant(tenant)" matTooltip="{{tenant.description}}" color="accent"
        mat-raised-button>
        {{tenant.name }}<br><span *ngIf="isTenantDescriptionVisible" class="truncate-text"
          style="font-size: 12px; text-align: left; display: block;font-weight: normal;">{{tenant.description}}</span></button>
    </div>
  </div>
</phoenix-dialog-new>