import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash-es';

import { PermissionService } from '../../../app/guards/permission.service';
import { PhoenixDynamicFormControl } from '../../components/phoenix-dialog/classes/phoenix-dynamic-form-control';
import { PhoenixDynamicFormControlType } from '../../components/phoenix-dialog/enums/phoenix-dynamic-form-control-type.enum';
import { PhoenixDialogData } from '../../components/phoenix-dialog/interfaces/phoenix-dialog-data';
import { PhoenixDialogComponent } from '../../components/phoenix-dialog/phoenix-dialog/phoenix-dialog.component';
import { PhoenixSnackbarService } from '../../components/phoenix-snackbar/phoenix-snackbar.service';
import { PhoenixUserConfigType } from '../../enums/phoenix-user-config-type.enum';
import { AccountApiService } from '../../gapicon/account/services/account-api.service';
import { AssetDto, UserConfigDto, UserDto } from '../../gapicon/dto.module';
import { Roles } from '../../gapicon/role/api/roles.enum';
import { UserApiService } from '../../gapicon/user/services/user-api.service';
import { PhoenixCommunicationSubject } from '../phoenix-communication-service/phoenix-communication-subject.enum';
import { PhoenixCommunicationService } from '../phoenix-communication-service/phoenix-communication.service';

import { EditUserDialogResult } from './classes/edit-user-dialog-result';
import { PhoenixWizardHelperService } from './phoenix-wizard-helper.service';

@Injectable({
  providedIn: 'root',
})
export class PhoenixUserWizardService {
  private editUserDto: UserDto;

  public constructor(
    private dialog: MatDialog,
    private accountApiService: AccountApiService,
    private translateService: TranslateService,
    private communicationService: PhoenixCommunicationService,
    private phoenixWizardHelper: PhoenixWizardHelperService,
    private phoenixSnackbarService: PhoenixSnackbarService,
    private permissionService: PermissionService,
    private userApiService: UserApiService,
  ) {
  }

  public async openEditUserWizard(userDto: UserDto): Promise<void> {
    userDto = await this.userApiService.getUserById(userDto.id).toPromise();
    this.editUserDto = userDto;
    await this.dialog.open(PhoenixDialogComponent, {
      width: this.phoenixWizardHelper.DIALOGWIDTH,
      autoFocus: false,
      data: <PhoenixDialogData>{
        headline: userDto.firstName + ' ' + userDto.lastName,
        subHeadline: 'WIZARDS.SELECTOR.SUBTITLE.PROFILESETTINGS',
        onClose: (dialogResult: EditUserDialogResult): Promise<void> => this.onEditUserCloseCallback(dialogResult),
        buttons: {
          cancel: true,
          save: true,
        },
        formControls: [
          this.editUserWizardColumn(userDto,
            'WIZARDS.SELECTOR.TEXTBOX.FIRSTNAME',
            'WIZARDS.SELECTOR.TEXTBOX.LASTNAME',
            'WIZARDS.SELECTOR.TEXTBOX.EMAIL',
            'WIZARDS.SELECTOR.DESCRIPTION.EMAIL',
            'WIZARDS.SELECTOR.SWITCH.ALARM',
            'WIZARDS.SELECTOR.TEXTBOX.PUSHNOTIFICATION',
            'WIZARDS.SELECTOR.DESCRIPTION.ALARM',
            'WIZARDS.SELECTOR.DESCRIPTION.PUSHNOTIFICATION',
            'WIZARDS.SELECTOR.TEXTBOX.COMPANY',
            'WIZARDS.SELECTOR.TEXTBOX.ROLE',
          ),
        ],
      },
    }).afterClosed().toPromise();
  }

  private convertTenantRolesToChips(roles: { [key: string]: string[] }): string[] {
    const rolesChips: string[] = [];
    _.forEach(roles, (tenants: string[], role: string) => {
      if (role === Roles.EnvironmentAdmin) {
        rolesChips.push(role);
      } else {
        _.forEach(this.permissionService.availableTenants, (tenant: AssetDto) => {
          if (_.includes(tenants, tenant.id)) {
            rolesChips.push(role + ':' + tenant.name);
          }
        });
      }
    });
    return rolesChips;
  }

  private createDynamicFormControlChips(key: string, label: string, value: string[], required: boolean, options: Object): PhoenixDynamicFormControl<string[]> {
    return new PhoenixDynamicFormControl<string[]>(key, PhoenixDynamicFormControlType.Chips, label, required, options, value);
  }

  private createDynamicFormControlSwitch(key: string, label: string, value: boolean, required: boolean, options: Object): PhoenixDynamicFormControl<boolean> {
    return new PhoenixDynamicFormControl<boolean>(key, PhoenixDynamicFormControlType.Switch, label, required, options, value);
  }

  private createDynamicFormControlText(key: string, label: string, value: string, required: boolean, options: Object): PhoenixDynamicFormControl<string> {
    return new PhoenixDynamicFormControl<string>(key, PhoenixDynamicFormControlType.Text, label, required, options, value);
  }

  private createDynamicFormControlTextBox(key: string, label: string, value: string, required: boolean, options: Object, disabled: boolean): PhoenixDynamicFormControl<string> {
    return new PhoenixDynamicFormControl<string>(key, PhoenixDynamicFormControlType.TextBox, label, required, options, value, disabled);
  }


  private editUserWizardColumn(
    userDto: UserDto,
    firstName: string,
    lastName: string,
    email: string,
    emailHint: string,
    notifications: string,
    pushNotifications: string,
    notificationsDescription: string,
    pushNotificationsDescription: string,
    company: string,
    role: string,
  ): PhoenixDynamicFormControl<string | boolean | string[]>[] {
    const formControlRow: PhoenixDynamicFormControl<string | boolean | string[]>[] = [];
    formControlRow.push(this.createDynamicFormControlTextBox('firstNameProfileSettings', firstName, userDto.firstName, true, {}, true));
    formControlRow.push(this.createDynamicFormControlTextBox('lastNameProfileSettings', lastName, userDto.lastName, true, {}, true));
    formControlRow.push(this.createDynamicFormControlText('emailProfileSettings', email, userDto.email, true, { 'hint': emailHint }));
    const notificationsHintSwitch: Object = { 'hint': notificationsDescription };
    formControlRow.push(this.createDynamicFormControlSwitch('notificationProfileSettings', notifications, this.getUserNotificationConfig(userDto), false, notificationsHintSwitch));
    const pushNotificationsHintSwitch: Object = { 'hint': pushNotificationsDescription };
    formControlRow.push(this.createDynamicFormControlSwitch('pushNotificationProfileSettings', pushNotifications, this.getUserPushNotificationConfig(userDto), false, pushNotificationsHintSwitch));
    formControlRow.push(this.createDynamicFormControlTextBox('companyProfileSettings', company, userDto.company, false, {}, false));
    //  formControlRow.push(this.createDynamicFormControlChips('rolesProfileSettings', role, this.convertTenantRolesToChips(userDto.roles), true, {}));

    return formControlRow;
  }

  private getUserConfiguration(userDto: UserDto, type: string): UserConfigDto {
    let config: UserConfigDto;
    if (userDto && userDto.configurations) {
      _.forEach(userDto.configurations, (c: UserConfigDto) => {
        if (c.type === type) {
          config = c;
        }
      });
    }
    return config;
  }

  private getUserNotificationConfig(userDto: UserDto): boolean {
    let notification: boolean = false;
    if (userDto && userDto.configurations) {
      _.forEach(userDto.configurations, (config: UserConfigDto) => {
        if (config.type === PhoenixUserConfigType.Notification) {
          notification = (config.value === 'true');
        }
      });
    }
    return notification;
  }


  private getUserPushNotificationConfig(userDto: UserDto): boolean {
    const pushNotificationActive = _.some(userDto.configurations, (conf: UserConfigDto) =>
      conf.type === PhoenixUserConfigType.PushNotification && conf.value === 'true'
    );

    const hasPushNotificationType = _.some(userDto.configurations, (conf: UserConfigDto) =>
      conf.type === PhoenixUserConfigType.PushNotification
    );

    if (pushNotificationActive || !hasPushNotificationType) {
      return true;
    }
    return false;
  }


  private async onEditUserCloseCallback(dialogResult: EditUserDialogResult): Promise<void> {
    if (dialogResult.result === 'save') {
      this.editUserDto.firstName = dialogResult.formData.firstNameProfileSettings;
      this.editUserDto.lastName = dialogResult.formData.lastNameProfileSettings;
      this.setNotificationStatus(this.editUserDto, dialogResult.formData.notificationProfileSettings);
      this.setPushNotificationStatus(this.editUserDto, dialogResult.formData.pushNotificationProfileSettings);
      this.editUserDto.company = dialogResult.formData.companyProfileSettings;
      await this.updateUser(this.editUserDto);
      this.phoenixSnackbarService.openPhoenixDefaultSnackbar(this.translateService.instant('WIZARDS.USER.EDIT.SUCCESS'));
      this.communicationService.emit(PhoenixCommunicationSubject.ReloadUserPage);
    }
    dialogResult.dialogRef.close();
  }

  private setNotificationStatus(userDto: UserDto, notification: boolean): void {
    let config: UserConfigDto = this.getUserConfiguration(userDto, PhoenixUserConfigType.Notification);
    if (!config) {
      config = new UserConfigDto();
      config.type = PhoenixUserConfigType.Notification;
      if (!userDto.configurations) {
        userDto.configurations = [];
      }
      userDto.configurations.push(config);
    }
    config.value = String(notification);
  }

  private setPushNotificationStatus(userDto: UserDto, pushNotification: boolean): void {
    let config: UserConfigDto = this.getUserConfiguration(userDto, PhoenixUserConfigType.PushNotification);
    if (!config) {
      config = new UserConfigDto();
      config.type = PhoenixUserConfigType.PushNotification;
      if (!userDto.configurations) {
        userDto.configurations = [];
      }
      userDto.configurations.push(config);
    }
    config.value = String(pushNotification);
  }

  private async updateUser(user: UserDto): Promise<UserDto> {
    const userDto: UserDto = await this.userApiService.updateUser(user).toPromise();
    this.communicationService.emit(PhoenixCommunicationSubject.UserUpdated, userDto);
    return userDto;
  }
}
